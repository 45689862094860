@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply  text-primary font-secondary bg-white;
  }
  .h1 {
    @apply text-[54px] lg:text-[108px] lg:text-left font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] mb-2;
  }
  .logo {
    @apply text-[35px] w-[300px] font-primary font-bold capitalize leading-[120%] tracking-[-0.05em] text-grey px-3 py-1 rounded-2xl;
  }
  .section {
    @apply lg:ml-[1rem];
  }
  .btn {
    @apply py-[18px] px-[50px] h-[66px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
  .sendBtn {
    @apply py-[13px] px-[30px] h-[50px] mt-[20px] flex items-center justify-center text-base uppercase font-secondary font-semibold bg-primary text-white;
  }
  .logoBtn {
    @apply py-[20px] px-[10px] h-[70px] mt-[20px] flex items-center justify-center text-base uppercase font-secondary font-semibold  text-black text-[80px];
  }
  .logoTag {
    @apply py-[10px] px-[15px] h-[30px] mt-[5px] mb-[20px] flex items-center justify-center text-base uppercase font-secondary font-bold bg-primary text-white text-[10px];
  }

}
